import PhotoIndreZebrauskaite from "../src/assets/photos/team-indre-zebrauskaite.jpg";
import PhotoLaimonasPetreikis from "../src/assets/photos/team-laimonas-petreikis.jpg";
import PhotoMarijaSaraite from "../src/assets/photos/team-marija-saraite.jpg";
import PhotoMartaSubataite from "../src/assets/photos/team-marta-subataite.jpg";
import PhotoMonikaStankeviciute from "../src/assets/photos/team-monika-stankeviciute.jpg";
import PhotoTomasGriska from "../src/assets/photos/team-tomas-griska.jpg";
import PhotoAgneSmagurauskaite from "../src/assets/photos/team-agne-smagurauskaite.jpg";
import PhotoDominykaUrbanaviciute from "../src/assets/photos/team-dominyka-urbanaviciute.jpg";
import PhotoDziugasVysniauskas from "../src/assets/photos/team-dziugas-vysniauskas.jpg";
import PhotoSamantaPargaliauskaite from "../src/assets/photos/team-samanta-pargaliauskaite.jpg";
import PhotoNaglisAndriejunas from "../src/assets/photos/team-naglis-andriejunas.jpg";

export const TEAM = [
  {
    key: "laimonas",
    name: "Laimonas Petreikis",
    photo: PhotoLaimonasPetreikis,
    position: "executiveMale"
  },

  // coms

  {
    key: "indre",
    name: "Indrė Žebrauskaitė",
    photo: PhotoIndreZebrauskaite,
    position: "coms"
  },

  // legal

  {
    key: "samanta",
    name: "Samanta Pargaliauskaitė",
    photo: PhotoSamantaPargaliauskaite,
    position: "legal"
  },
  {
    key: "agne",
    name: "Agnė Smagurauskaitė",
    photo: PhotoAgneSmagurauskaite,
    position: "legal"
  },
  {
    key: "dominyka",
    name: "Dominyka Urbanavičiūtė",
    photo: PhotoDominykaUrbanaviciute,
    position: "legal"
  },

  // revision

  {
    key: "monika",
    name: "Monika Stankevičiūtė",
    photo: PhotoMonikaStankeviciute,
    position: "revision"
  },
  {
    key: "marija",
    name: "Marija Šaraitė",
    photo: PhotoMarijaSaraite,
    position: "revision"
  },
  {
    key: "marta",
    name: "Marta Subataitė",
    photo: PhotoMartaSubataite,
    position: "revision"
  },

  // other

  {
    key: "naglis",
    name: "Naglis Andriejūnas",
    photo: PhotoNaglisAndriejunas,
    position: "memberMale"
  },
  {
    key: "tomas",
    name: "Tomas Griška",
    photo: PhotoTomasGriska,
    position: "memberMale"
  },
  {
    key: "dziugas",
    name: "Džiugas Vyšniauskas",
    photo: PhotoDziugasVysniauskas,
    position: "memberMale"
  }
];
