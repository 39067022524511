import React from "react";
import { graphql } from "gatsby";

import { TEAM } from "../../../content/team";

import BannerBackground from "../../assets/photos/banner-team.jpg";

import { useTranslations } from "../../hooks/translations";
import SEO from "../../components/seo";
import JoinUs from "../../components/about/join-us";
import Banner from "../../components/banner";

import styles from "./team.module.scss";

export const query = graphql`
  query TeamQuery($locale: String!) {
    translations(locale: $locale) {
      pages {
        about {
          team
        }
      }
      joinUs
      ...LayoutTranslations
    }
  }
`;

const HistoryPage = () => {
  const t = useTranslations("pages.about.team");

  return (
    <>
      <SEO title={t.title} description={t.metaDescription} />

      <Banner
        heading={t.headingTop}
        description={t.descriptionTop}
        background={BannerBackground}
        alignment="right"
      />

      <section>
        <h1>{t.heading}</h1>
        <div className="bp-container">
          <ul className={styles.teamMembers}>
            {TEAM.map(({ key, name, photo, position }) => {
              return (
                <li key={key} className={styles.teamMember}>
                  <img src={photo} width="164" alt="" />
                  <p className={styles.position}>{t.positions[position]}</p>
                  <p className={styles.name}>{name}</p>
                  {/* Temporarily hidden */}
                  {/* <p className={styles.email}>
                    <a href={`mailto:${email}`} className="bp-link">
                      {email}
                    </a>
                  </p> */}
                </li>
              );
            })}
          </ul>
        </div>
      </section>

      <JoinUs />
    </>
  );
};

export default HistoryPage;
